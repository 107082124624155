import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  ContactForm,
  FadeReveal,
  Content,
  Location,
  SocialMedia,
  Map,
  HoursOfOperation,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout
          className={"contact-page"}
          hero={
            <HeroElement
              header={""}
              tagline={""}
              className={"contact-hero"}
              images={[
                "https://fisherman.gumlet.io/public/47372a28-2bba-411e-b98b-6a79e266eae9/10916701_10152816685329671_873838908348694071_o.jpg?width=1250",
              ]}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"contact-container"}
          >
            <Grid.Column width={16}>
              <div className={"contact-form-container"}>
                <ContactForm
                  buttonText={"Submit"}
                  centeredHeaders={false}
                  hideOptIn={false}
                  className={""}
                  fluidButton={false}
                  header={
                    <Grid className={"contact-form-header"}>
                      <Grid.Column width={16} textAlign={"center"}>
                        <FadeReveal triggerOnce={true} />
                        <Content>
                          <Content.Markup isMarkdown={false} width={16}>
                            <Header
                              as={"h1"}
                              content={getComponentContentNodeContent({
                                components:
                                  fishermanBusinessWebsitePage.components,
                                componentId: "contact_header",
                                defaultValue: "Contact",
                              })}
                            />
                            <Location
                              address={{
                                street:
                                  fishermanBusiness.primaryLocation.street,
                                city: fishermanBusiness.primaryLocation.city,
                                state: fishermanBusiness.primaryLocation.state,
                                zip: fishermanBusiness.primaryLocation.zipCode,
                              }}
                            />
                            <SocialMedia
                              socialMediaValues={fishermanBusiness.socialMedia}
                              buttonType={"rect"}
                              groupButtons={false}
                              showLabels={false}
                            />
                          </Content.Markup>
                          <Content.Markup
                            isMarkdown={true}
                            textAlign={"center"}
                            content={getComponentContentNodeContent({
                              components:
                                fishermanBusinessWebsitePage.components,
                              componentId: "contact_form_description",
                            })}
                          />
                        </Content>
                      </Grid.Column>
                    </Grid>
                  }
                  inline={false}
                  showLabels={true}
                  subheader={""}
                  termsCopy={""}
                  withContainer={false}
                  locationId={fishermanBusiness.primaryLocation._id}
                  businessId={fishermanBusiness._id}
                  businessType={fishermanBusiness.type}
                  event={{ category: "Contact", action: "Submit Inquiry" }}
                />
                <div className={"contact-form-footer"}>
                  <div>
                    <Map
                      address={{
                        street: fishermanBusiness.primaryLocation.street,
                        city: fishermanBusiness.primaryLocation.city,
                        state: fishermanBusiness.primaryLocation.state,
                        zip: fishermanBusiness.primaryLocation.zipCode,
                      }}
                      businessName={fishermanBusiness.name}
                    />
                  </div>
                  <div>
                    <HoursOfOperation
                      hours={fishermanBusiness.primaryLocation.hours}
                      displayOption={"grouped"}
                      enhancedLabels={{
                        openingSoon: "Opening soon",
                        open: "Open now",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        street
        city
        state
        zipCode
        _id
        hours {
          day
          open
          close
          label
        }
      }
      socialMedia {
        link
        type
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Contact" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
  }
`;
